import PropTypes from "prop-types"
import React, { useState } from "react"
import styles from "./header.module.css"
import logo from "../images/truck-lite-2.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"
const Header = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <nav className={styles.header}>
      <a className={styles.logoContainer} href="/"><img className={styles.logo} src={logo} alt="Site Logo" /></a>
      <a className={styles.logoLink} href="/">
        <span className={styles.headerText}>
          {siteTitle}
        </span>
      </a>
      <div className={styles.spacer} />
      <button className={styles.bars}>
        <FontAwesomeIcon icon={faBars} onClick={() => setShowMenu(!showMenu)} />
      </button>
      <div className={clsx({[styles.navItems]: true, [styles.show]: showMenu})}>
        <button className={styles.close}><FontAwesomeIcon icon={faTimesCircle} onClick={() => setShowMenu(!showMenu)} /></button>
        <ul className={styles.ul}>
          <li>
            <a href="/howItWorks">How it works</a>
          </li>
          <li>
            <a href="/faq">FAQ</a>
          </li>
          <li>
            <a href="/junk101">Junk Removal 101</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
