/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import clsx from 'clsx';


import Header from "./header"
import styles from "./layout.module.css"
import "./normalize.css";
import "./theme.css";
import Breadcrumb from "./listings/breadcrumb";
import Footer from "./footer";
const Layout = ({ children, fluid, breadcrumbText, mobilePad }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {breadcrumbText && <Breadcrumb text={breadcrumbText} />}
        <main className={clsx({ 
          [styles.mainContentWrap]: true,
          [styles.nonFluid]: !!!fluid,
          })}>{children}</main>
          <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
