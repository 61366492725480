import React from 'react';
import styles from "./footer.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.hideOnMobile}>Junk Removal Dispatch LLC</div>
            <div className={`${styles.spacer} ${styles.hideOnMobile}`}/>
            <div>
                <a className={`${styles.unstyleLink} ${styles.hideOnMobile}`} href="/become-a-service-provider">Become A Service Provider</a>
                <FontAwesomeIcon icon={faTwitter} size="1x" className={styles.social} />
                <FontAwesomeIcon icon={faFacebook} size="1x" className={styles.social} />
                <FontAwesomeIcon icon={faYoutube} size="1x" className={styles.social} />
                <FontAwesomeIcon icon={faInstagram} size="1x" className={styles.social} />
            </div>
        </footer>
    )
}